import { BpEventType } from '../EventsList/EventsList';
import styles from './EventItem.module.scss';
import dayjs from 'dayjs';
import { forwardRef, Ref } from 'react';
import classNames from 'classnames';
import { BirthdayIcon, Button, DotsVerticalIcon, Dropdown, DropdownMenu, DropdownMenuItem } from '@bp/ui-components';
import { useBpDeleteCalendarEventMutation } from '../../client/bp-graphql-client-defs';
import { useTranslation } from 'react-i18next';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { eventColors } from '../../utils/dateCalculations';
import { useWindowDimensions } from 'utils/dimensions';
import { useConfirm } from 'hooks/useConfirm';

type EventItemProps = {
  event: BpEventType;
  showClassNames?: boolean;
  isCompact: boolean;
  isEmbedded?: boolean;
  isActive?: boolean;
  onClick?: (bpEvent: BpEventType) => void;
  onEdit?: (uuid: string) => void;
  className?: string;
};

export const EventItem = forwardRef(
  (
    { event, showClassNames, isCompact, isEmbedded = false, isActive, className, onEdit, onClick }: EventItemProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { t } = useTranslation();
    const context = useMemoizedCacheTag('EVENT');

    const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
      defaultTitle: t('delete.headline'),
      defaultMessage: t('delete.message', { type: t('appointments.titleSingular'), context: 'male' }),
      defaultConfirmText: t('delete.delete'),
    });

    const { isPhone } = useWindowDimensions();

    const isBirthday = event.type === 'birthday';
    const isEditable = onEdit !== undefined;

    const duration = dayjs.duration(Number(event.duration), 's');
    const start = dayjs(event?.start).format('HH:mm').toString();
    const end = dayjs(event?.start).add(duration).format('HH:mm');

    const type = (event: Partial<BpEventType> | undefined) => {
      if (event) {
        const locCount = event.locationsCount ?? 0;
        const virtLocCount = event.virtualLocationsCount ?? 0;
        if (locCount === 0 && virtLocCount > 0) {
          return isPhone ? t('events.virtualShort') : t('events.virtual');
        } else if (locCount > 0 && virtLocCount === 0) {
          return t('events.onSite');
        } else if (locCount === 0 && virtLocCount === 0) {
          return t('events.noSite');
        }
        return isPhone ? t('events.hybridShort') : t('events.hybrid');
      }
      return t('events.noSite');
    };

    const [, deleteEvent] = useBpDeleteCalendarEventMutation();

    const handleDelete = () => {
      if (event.uuid) deleteEvent({ where: { uuid: event.uuid } }, context);
    };

    const actions: DropdownMenuItem[] = [
      {
        onClick: () => {
          if (onEdit) onEdit(event.uuid);
        },
        label: t('common.edit'),
      },
      { type: 'ruler' },
      {
        label: t('delete.delete'),
        color: 'error',
        onClick: async () => {
          const res = await confirmDelete();
          if (res) {
            handleDelete();
          }
        },
      },
    ];

    const eventItemClasses = classNames(
      styles['event-item'],
      {
        [styles.compact]: isCompact,
        [styles.embedded]: isEmbedded,
        [styles.active]: isActive,
        [styles.birthday]: isBirthday,
      },
      className,
    );
    const color = eventColors(event.categories ?? [], isBirthday);
    const dayClasses = classNames(styles.day, styles[color]);

    return (
      <>
        <div
          id={event.uuid}
          className={eventItemClasses}
          onClick={() => {
            if (onClick) {
              onClick(event);
            }
          }}
          ref={ref}
        >
          <div className={dayClasses}>
            {showClassNames && event.classShortNames?.length
              ? event.classShortNames.join(',')
              : dayjs(event.start).format('D')}
          </div>
          <div className={styles.content}>
            <div className={styles.top}>
              {isBirthday && <BirthdayIcon className={classNames(styles.icon, 'svg-icon')} />}
              <div className={styles.title}>{event.title}</div>
              {!isBirthday && (
                <div className={styles.info}>
                  {start} - {end} | {type(event)}
                  <div className={styles.time}></div>
                  <div className={styles.type}></div>
                </div>
              )}
            </div>
            {!isBirthday && (
              <>
                {isCompact && event.originDisplayName !== '' && (
                  <div className={styles.origin}>{event.originDisplayName}</div>
                )}
                {!isCompact && (
                  <>
                    {event.description && <div className={styles.description}>{event.description}</div>}
                    {event.keywords && event.keywords.length > 0 && <div className={styles.tag}>{event.keywords}</div>}
                  </>
                )}
              </>
            )}
          </div>
          {!isBirthday && isEditable && (
            <div className={styles.action}>
              <Dropdown noPadding trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon className='small' />} />}>
                <DropdownMenu data={actions} />
              </Dropdown>
            </div>
          )}
        </div>

        <ConfirmationDialog />
      </>
    );
  },
);
EventItem.displayName = 'EventItem';
