import { Button, ButtonGroup, CoursesIcon, DeleteIcon, EditIcon, Table, TableColumns } from '@bp/ui-components';
import { useAbsenceReasonsQuery } from 'client/bp-graphql-client-defs';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { absenceReasonsAsString } from '../../../utils/absenceReasonAsString';

type AbsenceReasonTableProps = {
  isLoading: boolean;
  onEdit: (uuid: string) => void;
  onDelete: (uuid: string) => void;
};

type AbsenceReasonTableType = {
  uuid: string;
  reason: string;
  isInternal: boolean;
};

export const AbsenceReasonTable = ({ isLoading, onEdit, onDelete }: AbsenceReasonTableProps) => {
  const { t } = useTranslation();
  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();
  const context = useMemoizedCacheTag('ABSENCE_REASONS');

  const [{ data }] = useAbsenceReasonsQuery({
    variables: { where: { organization: { uuid: organizationUuid } } },
    context,
  });

  const columns: TableColumns<AbsenceReasonTableType>[] = useMemo(() => {
    return [
      {
        header: t('absenceReasons.title', { context: 'short' }),
        id: 'reason',
        accessorKey: 'reason',
        size: 450,
      },
      {
        header: t('absenceReasons.isInternal'),
        id: 'isInternal',
        accessorKey: 'isInternal',
        type: 'boolean',
        size: 100,
      },
    ];
  }, [t]);

  const tableData: AbsenceReasonTableType[] =
    data?.absenceReasons.map((a) => {
      return {
        uuid: a.uuid,
        reason: absenceReasonsAsString(a.reason),
        isInternal: a.internal ?? false,
      };
    }) ?? [];

  return (
    <Table<AbsenceReasonTableType>
      columns={columns}
      data={tableData}
      sorting={[{ id: 'reason', desc: false }]}
      customPadding='var(--spacing-7)'
      emptyStateSettings={{ icon: <CoursesIcon /> }}
      breakpoint='500px'
      lastColWidth='68px'
      lastCol={(row) => {
        const reason = data?.absenceReasons.find((a) => a.uuid === row.original.uuid);
        const isInUse = reason?.absencesAggregate?.count !== undefined && reason.absencesAggregate.count > 0;

        return (
          <ButtonGroup disabled={isInUse} tooltip={isInUse ? t('absenceReasons.isInUse') : undefined}>
            <Button hierarchy='secondary' type='button' onClick={() => onEdit(row.original.uuid)} icon={<EditIcon />} />
            <Button
              hierarchy='secondary'
              type='button'
              onClick={() => onDelete(row.original.uuid)}
              icon={<DeleteIcon color={!isInUse ? 'var(--color-error)' : undefined} />}
            />
          </ButtonGroup>
        );
      }}
      loading={isLoading}
    />
  );
};
